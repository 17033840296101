export const catNose = `M43.107,60.675c0,4.168,7.546,7.55,7.546,7.55s7.55-3.382,7.55-7.55S43.107,56.507,43.107,60.675z`;

export const catEar = `M98.236,69.06l-9.263-1.48c0.557-1.164,1.032-2.373,1.438-3.615l7.062,1.131c0.13,0.021,0.258,0.028,0.386,0.028
  c1.167,0,2.193-0.845,2.382-2.034c0.212-1.317-0.686-2.558-2.003-2.769l-6.675-1.067c0.28-1.726,0.427-3.508,0.427-5.343
  c0-6.845-4.561-22.027-6.425-26.725c-1.431-3.602-2.868-5.95-4.531-8.67c-0.774-1.264-1.604-2.621-2.514-4.245
  c-0.403-0.718-1.142-1.181-1.964-1.23c-0.849-0.062-1.611,0.324-2.097,0.987c-3.021,4.139-7.708,8.75-9.605,8.75
  c-0.775,0-2.202-0.176-3.855-0.38c-2.922-0.36-6.562-0.81-10.338-0.81c-3.111,0-6.12,0.394-8.776,0.741
  c-1.843,0.241-3.434,0.449-4.533,0.449c-3.984,0-8.543-6.114-9.827-8.256c-0.434-0.723-1.213-1.17-2.058-1.175
  c-0.881-0.004-1.628,0.427-2.072,1.145c-2.846,4.602-4.725,7.642-6.769,12.961C14.749,32.337,10.35,47.23,10.35,53.91
  c0,1.735,0.136,3.422,0.386,5.062L2.311,60.32c-1.317,0.209-2.215,1.451-2.004,2.769c0.189,1.188,1.217,2.034,2.383,2.034
  c0.126,0,0.255-0.008,0.385-0.028l8.765-1.402c0.394,1.244,0.859,2.453,1.403,3.62L2.307,69.06
  c-1.317,0.211-2.215,1.451-2.005,2.771c0.19,1.187,1.217,2.034,2.383,2.034c0.127,0,0.256-0.01,0.386-0.028l12.746-2.039
  c6.81,9.795,19.501,15.824,35.349,15.824c15.708,0,28.317-5.926,35.172-15.568l11.136,1.781c0.13,0.021,0.258,0.029,0.386,0.029
  c1.167,0,2.193-0.847,2.382-2.034C100.452,70.511,99.553,69.271,98.236,69.06z `;

export const catHead = `M51.165,82.786
  c-11.613,0-23.289-3.759-30.068-11.836l2.122-0.338c1.318-0.211,2.215-1.45,2.004-2.77c-0.211-1.317-1.452-2.215-2.767-2.004
  l-4.327,0.693c-0.595-1.138-1.106-2.338-1.531-3.601l6.621-1.057c1.318-0.212,2.215-1.451,2.004-2.77
  c-0.211-1.316-1.452-2.215-2.767-2.005l-6.967,1.115c-0.195-1.376-0.31-2.805-0.31-4.302c0-5.423,3.863-19.281,5.955-24.725
  c1.408-3.665,2.707-6.117,4.445-9.015c2.48,3.151,6.775,7.44,11.767,7.44c1.414,0,3.149-0.227,5.16-0.489
  c2.508-0.328,5.352-0.7,8.15-0.7c3.479,0,6.953,0.428,9.746,0.772c1.886,0.232,3.375,0.417,4.446,0.417
  c3.909,0,8.436-4.511,11.267-7.873c0.271,0.448,0.534,0.88,0.793,1.301c1.609,2.635,2.883,4.715,4.16,7.93
  c2.081,5.247,6.083,19.387,6.083,24.941c0,1.6-0.129,3.12-0.352,4.58l-8.715-1.394c-1.33-0.215-2.558,0.687-2.769,2.005
  c-0.211,1.317,0.688,2.557,2.004,2.769l8.316,1.329c-0.438,1.258-0.958,2.46-1.567,3.592l-5.984-0.957
  c-1.332-0.212-2.559,0.687-2.77,2.004s0.688,2.558,2.003,2.769l3.694,0.592C74.207,79.103,62.656,82.786,51.165,82.786z`;

export const dogNose = `M36.399,64.384c0,7.885,8.166,14.282,14.277,14.282c6.111,0,14.28-6.397,14.28-14.282
C64.956,56.5,36.399,56.5,36.399,64.384z`;

export const dogEar = `M100.659,42.526c-9.083-28.637-34.646-30.306-43.947-29.92c-1.93-0.323-3.935-0.503-6.034-0.503
c-2.171,0-4.188,0.181-6.067,0.503c-9.299-0.387-34.867,1.279-43.952,29.92c0,0,2.469,8.597,14.729,9.255
c-0.775,3.45-1.324,6.718-1.324,8.962c0,18.046,15.247,30.65,37.077,30.65c21.829,0,37.075-12.604,37.075-30.65
c0-2.271-0.555-5.556-1.339-9.012C98.336,50.702,100.659,42.526,100.659,42.526z `;

export const dogHead = `M51.139,86.646
c-16.09,0-32.327-8.009-32.327-25.9c0-2.71,1.074-7.764,2.354-12.563c5.087-6.791,12.715-21.385,19.399-29.519
c2.853-1.112,6.173-1.808,10.112-1.808c3.825,0,7.093,0.62,9.941,1.643c7.361,8.878,15.897,25.73,20.932,31.409
c1.076,4.235,1.913,8.434,1.913,10.838C83.464,78.637,67.229,86.646,51.139,86.646z`;
